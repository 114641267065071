<template>
    <BT-Blade-Items
        v-if="locationSelected"
        addBladeName="stock-import"
        archiveBladeName="stock-import-archives"
        bladeName="stock-imports"
        :bladesData="bladesData"
        canSearchLocal
        :getParams="bData => { return { includeDetails: false, supplierIDs: bData.data == null ? bData.data.supplierID : null, locationID: locationID } }"
        :headers="[
            { text: 'CO#', value: 'consignmentNumber', csv: true, subtitle: 3, searchable: true, prefix: 'Consignment #' },
            { text: 'Due Date', value: 'dueDate', textFilter: 'toDayMonth', csv: true, subtitle: 1, prefix: 'Due: ', searchable: true },
            { text: 'Supplier', value: 'seller.companyName', csv: true, title: 1, searchable: true },
            { text: 'Departure', value: 'departureLocation', textFilter: 'toFamiliarLocationLine', csv: true, subtitle: 1, prefix: 'From: ', searchable: true },
            { text: 'Destination', value: 'destinationLocation.locationName', csv: true, subtitle: 2, prefix: 'To: ', searchable: true }]"
        hideActions
        :loadingMsg="loadingMsg"
        navigation="stock-imports"
        :refreshToggle="refreshToggle"
        showList
        title="Stock Imports"
        useServerPagination>
        <template v-slot:toolbar>
            <v-toolbar-title>
                <BT-Entity
                    alternateText="Stock Imports"
                    navigation="locations"
                    :itemValue="locationID"
                    itemText="locationName">
                    <template v-slot:prepend><span>Stock Imports: </span></template>
                </BT-Entity>
            </v-toolbar-title>
        </template>
        <template v-slot:actionsRight>
            <BT-Menu-Select
                
                icon="mdi-map-marker-radius"
                itemText="locationName"
                itemValue="id"
                navigation="locations"
                v-model="locationID" />
        </template>
        <template v-slot:listItem="{ item }">
            <v-list-item-avatar rounded>
                <v-img :src="companyLogoURL(item.sellerID)" class="my-auto">
                    <template v-slot:placeholder><v-icon class="primary--text" size="40">mdi-account-outline</v-icon></template>
                </v-img>
            </v-list-item-avatar>
            <v-list-item-content>
                <v-list-item-title>{{ item.seller.companyName }}</v-list-item-title>
                <v-list-item-subtitle v-if="item.isTransfer">
                    {{ item.departureLocation.locationName }}
                    <v-icon small class="mx-1">mdi-transfer-right</v-icon>
                    {{ item.destinationLocation.locationName }}
                </v-list-item-subtitle>
                <v-list-item-subtitle v-else>{{ item.destinationLocation.locationName }}</v-list-item-subtitle>
                <v-list-item-subtitle>
                    <span v-if="item.isSellerResolved">Delivered on {{ item.performedOn | toDayShortDateAndTime }}</span>
                    <span v-else-if="item.isBuyerResolved">Imported</span>
                    <span v-else>Pending</span>
                </v-list-item-subtitle>
                <!-- <v-list-item-subtitle>{{ item.isSellerResolved ? 'Delivered ' : (item.isBuyerResolved ? 'Imported' : 'Pending') }}</v-list-item-subtitle> -->
            </v-list-item-content>
            <v-list-item-action>
                <v-list-item-action-text>
                    CO# {{ item.consignmentNumber }}
                </v-list-item-action-text>
                {{ item.dueDate | toDayMonth }}
            </v-list-item-action>
        </template>
    </BT-Blade-Items>
</template>

<script>
export default {
    name: 'Stock-Imports-Blade',
    components: {
        BTMenuSelect: () => import('~components/BT-Menu-Select.vue')
    },
    data: function() {
        return {
            bladeData: null,
            loadingMsg: null,
            locationID: null,
            locationSelected: false,
            msg: null,
            refreshToggle: false
        }
    },
    props: {
        bladesData: {
            type: Object,
            default: null
        },
    },
    watch: {
        locationID() {
            this.refreshToggle = !this.refreshToggle;
        }
    },
    async mounted() {
        if (this.locationID == null || this.locationSelected == false) {
            
            try {
                this.locationID = this.selectedLocationID();
            }
            finally {
                this.locationSelected = true;
            }
        }
    },
    methods: {
        getParams(bladeData) {
            return { includeDetails: false, supplierIDs: bladeData.data == null ? null : bladeData.data.supplierID, locationID: this.locationID };
        }
    }
}
</script>