var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.locationSelected)?_c('BT-Blade-Items',{attrs:{"addBladeName":"stock-import","archiveBladeName":"stock-import-archives","bladeName":"stock-imports","bladesData":_vm.bladesData,"canSearchLocal":"","getParams":function (bData) { return { includeDetails: false, supplierIDs: bData.data == null ? bData.data.supplierID : null, locationID: _vm.locationID } },"headers":[
        { text: 'CO#', value: 'consignmentNumber', csv: true, subtitle: 3, searchable: true, prefix: 'Consignment #' },
        { text: 'Due Date', value: 'dueDate', textFilter: 'toDayMonth', csv: true, subtitle: 1, prefix: 'Due: ', searchable: true },
        { text: 'Supplier', value: 'seller.companyName', csv: true, title: 1, searchable: true },
        { text: 'Departure', value: 'departureLocation', textFilter: 'toFamiliarLocationLine', csv: true, subtitle: 1, prefix: 'From: ', searchable: true },
        { text: 'Destination', value: 'destinationLocation.locationName', csv: true, subtitle: 2, prefix: 'To: ', searchable: true }],"hideActions":"","loadingMsg":_vm.loadingMsg,"navigation":"stock-imports","refreshToggle":_vm.refreshToggle,"showList":"","title":"Stock Imports","useServerPagination":""},scopedSlots:_vm._u([{key:"toolbar",fn:function(){return [_c('v-toolbar-title',[_c('BT-Entity',{attrs:{"alternateText":"Stock Imports","navigation":"locations","itemValue":_vm.locationID,"itemText":"locationName"},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('span',[_vm._v("Stock Imports: ")])]},proxy:true}],null,false,2540405226)})],1)]},proxy:true},{key:"actionsRight",fn:function(){return [_c('BT-Menu-Select',{attrs:{"icon":"mdi-map-marker-radius","itemText":"locationName","itemValue":"id","navigation":"locations"},model:{value:(_vm.locationID),callback:function ($$v) {_vm.locationID=$$v},expression:"locationID"}})]},proxy:true},{key:"listItem",fn:function(ref){
        var item = ref.item;
return [_c('v-list-item-avatar',{attrs:{"rounded":""}},[_c('v-img',{staticClass:"my-auto",attrs:{"src":_vm.companyLogoURL(item.sellerID)},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-icon',{staticClass:"primary--text",attrs:{"size":"40"}},[_vm._v("mdi-account-outline")])]},proxy:true}],null,true)})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.seller.companyName))]),(item.isTransfer)?_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(item.departureLocation.locationName)+" "),_c('v-icon',{staticClass:"mx-1",attrs:{"small":""}},[_vm._v("mdi-transfer-right")]),_vm._v(" "+_vm._s(item.destinationLocation.locationName)+" ")],1):_c('v-list-item-subtitle',[_vm._v(_vm._s(item.destinationLocation.locationName))]),_c('v-list-item-subtitle',[(item.isSellerResolved)?_c('span',[_vm._v("Delivered on "+_vm._s(_vm._f("toDayShortDateAndTime")(item.performedOn)))]):(item.isBuyerResolved)?_c('span',[_vm._v("Imported")]):_c('span',[_vm._v("Pending")])])],1),_c('v-list-item-action',[_c('v-list-item-action-text',[_vm._v(" CO# "+_vm._s(item.consignmentNumber)+" ")]),_vm._v(" "+_vm._s(_vm._f("toDayMonth")(item.dueDate))+" ")],1)]}}],null,false,3136895401)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }